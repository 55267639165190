.home-title.ios {
    padding-left: 20px;
    text-align: left;
}

ion-item.selected {
    --background: var(--ion-color-light-shade);
}

ion-header.no-borders::after {
    background: none;
}

ion-header.no-borders ion-toolbar {
    --border-width: 0 !important;
}

ion-modal.ion-modal-scrollable .ion-page {
    overflow: auto;
}

@media only screen and (min-height: 600px) and (min-width: 768px) {
    ion-modal .modal-wrapper {
        border-radius: 16px !important;
    }
}

@media screen and (min-width: 992px) {
    ion-grid.scrollable {
        height: 100%;
    }
    ion-row.scrollable {
        height: 100%;
    }
    ion-col.scrollable {
        height: 100%;
        overflow: auto;
    }
}

.ion-text-bold {
    font-weight: bold;
}

.ion-letter-spacing-1 {
    letter-spacing: 1px;
}

.ion-letter-space-2 {
    letter-spacing: 2px;
}
